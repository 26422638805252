import { createIcon } from '@chakra-ui/react'

export const ShapeShiftWordAndLogoMark = createIcon({
  displayName: 'ShapeShiftWordAndLogoMark',
  path: (
    <g id='Mock-Ups' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Landing-Page' transform='translate(-172.000000, -9891.000000)' fill='#10151F'>
        <g id='Footer' transform='translate(0.000000, 9590.000000)'>
          <g transform='translate(172.261402, 301.500000)' id='SS_vertical_RGB'>
            <g transform='translate(0.150561, 0.136502)'>
              <polygon
                id='Fill-1'
                points='191.596705 112.745754 195.446098 112.745754 195.446098 89.8586726 191.596705 89.8586726'
              ></polygon>
              <g id='Group-5' transform='translate(0.000000, 78.770557)'>
                <polygon
                  id='Fill-2'
                  points='191.596705 8.10709712 195.446098 8.10709712 195.446098 4.25827324 191.596705 4.25827324'
                ></polygon>
                <path
                  d='M13.9582421,4.03933157 C9.77560322,4.03933157 5.31089927,5.68338444 5.31089927,9.678359 C5.31089927,13.2502359 9.39970597,14.2840955 14.2397385,15.0830904 C20.8199307,16.11695 27.3051535,17.4328747 27.3051535,24.858125 C27.2579531,32.2367434 20.2086001,34.6337282 13.9110417,34.6337282 C8.08378126,34.6337282 2.53801729,32.5188086 0,27.020245 L3.38364393,25.0463579 C5.49856356,28.9475003 9.9160671,30.8264179 13.9582421,30.8264179 C17.9526479,30.8264179 23.2635472,29.5582623 23.2635472,24.7170924 C23.3101789,20.6288544 18.7044424,19.4533935 13.7700091,18.701599 C7.42525022,17.7149398 1.2687243,16.2579826 1.2687243,9.58452686 C1.17489216,2.72283817 8.1776134,0.373622532 13.8638412,0.373622532 C18.7516429,0.373622532 23.4040111,1.36028173 26.3179256,6.34191548 L23.2163468,8.26860214 C21.430124,5.40188802 17.6233824,4.0859633 13.9582421,4.03933157'
                  id='Fill-4'
                ></path>
              </g>
              <path
                d='M34.2538502,79.9113281 L34.2538502,93.3992721 C36.2283059,90.3448937 39.3298847,89.3110341 42.2909997,89.2638337 C48.8228542,89.2638337 52.0182651,93.5875051 52.0182651,99.9788957 L52.0182651,112.762246 L48.2120922,112.762246 L48.2120922,100.026096 C48.2120922,95.46756 45.8623079,92.8823423 41.6324687,92.8823423 C37.4026294,92.8823423 34.3010506,96.2193545 34.3010506,100.448625 L34.3010506,112.762246 L30.4471086,112.762246 L30.4471086,79.8641277 L34.2538502,79.8641277 L34.2538502,79.9113281'
                id='Fill-6'
              ></path>
              <path
                d='M67.3492999,92.8192188 C62.7435634,92.8192188 59.0306539,96.1095993 59.0306539,101.278897 C59.0306539,106.448764 62.7435634,109.785208 67.3492999,109.785208 C78.5340913,109.785208 78.5340913,92.7725871 67.3959316,92.7725871 L67.3492999,92.8192188 Z M75.8084097,112.840155 L75.7145776,108.610315 C73.8817231,111.806295 70.3570466,113.310453 67.1610669,113.310453 C60.4876112,113.310453 55.2711128,108.704716 55.2711128,101.137865 C55.2711128,93.6187824 60.582012,89.1068781 67.2082673,89.1540785 C70.6863121,89.1540785 74.1165878,90.7520683 75.8084097,93.759815 L75.8556101,89.5299757 L79.6151513,89.5299757 L79.6151513,112.745754 L75.9028105,112.745754 L75.8084097,112.840155 L75.8084097,112.840155 Z'
                id='Fill-7'
              ></path>
              <path
                d='M103.657221,101.278897 C103.657221,96.0623989 100.226377,92.9602514 95.4801762,92.9602514 C90.639575,92.9602514 87.3491946,96.532697 87.3491946,101.278897 C87.3491946,106.025666 90.7806076,109.596975 95.4801762,109.644175 C100.132544,109.644175 103.657221,106.495396 103.657221,101.278897 Z M83.1665557,89.6238079 L86.8328335,89.6238079 L87.0204977,94.0885118 C88.8533523,90.986933 92.2841966,89.2007102 95.9499056,89.2007102 C102.623361,89.3417428 107.510594,94.0418801 107.510594,101.185065 C107.510594,108.516483 102.670562,113.263252 95.7144723,113.263252 C92.4252292,113.263252 88.7589514,111.853495 86.9266656,108.563684 L86.9266656,123.696249 L83.1665557,123.696249 L83.1665557,89.6238079 L83.1665557,89.6238079 Z'
                id='Fill-8'
              ></path>
              <path
                d='M129.997894,99.4466115 C129.810229,94.9347072 126.708651,92.490522 122.150115,92.490522 C118.108508,92.490522 114.583263,94.9347072 113.925301,99.3994111 L129.997894,99.3994111 L129.997894,99.4466115 Z M132.254415,109.033413 C129.904062,111.806295 125.862455,113.16942 122.243947,113.16942 C115.053561,113.16942 110.024727,108.422651 110.024727,101.044033 C110.024727,94.0418801 115.053561,89.0130459 122.055714,89.0130459 C129.152267,89.0130459 134.556999,93.3839177 133.664171,102.642022 L113.925301,102.642022 C114.348398,106.918493 117.873075,109.596975 122.337779,109.596975 C124.969628,109.596975 128.165608,108.563684 129.716397,106.683629 L132.301046,109.033413 L132.254415,109.033413 L132.254415,109.033413 Z'
                id='Fill-9'
              ></path>
              <path
                d='M149.769179,82.8098883 C145.58654,82.8098883 141.121836,84.4539412 141.121836,88.4489157 C141.121836,92.0207926 145.210074,93.0546522 150.051244,93.8536471 C156.630868,94.8875067 163.116091,96.2034315 163.116091,103.628682 C163.069459,111.0073 156.019537,113.404285 149.722548,113.404285 C143.89415,113.404285 138.348954,111.289365 135.810937,105.790802 L139.194581,103.816915 C141.310069,107.718057 145.727573,109.596975 149.769179,109.596975 C153.763585,109.596975 159.074484,108.328819 159.074484,103.487649 C159.121116,99.3994111 154.515948,98.2239503 149.580946,97.4721558 C143.236187,96.4854966 137.08023,95.0285393 137.08023,88.3550836 C136.986398,81.4933949 143.988551,79.1441792 149.675347,79.1441792 C154.56258,79.1441792 159.215517,80.1308384 162.128863,85.1124722 L159.027284,87.0391589 C157.24163,84.1724447 153.434888,82.85652 149.769179,82.8098883'
                id='Fill-10'
              ></path>
              <path
                d='M169.982897,79.8959737 L169.982897,93.3839177 C171.956785,90.3295393 175.058363,89.295111 178.019478,89.2479106 C184.551901,89.2479106 187.747312,93.5721507 187.747312,99.9629726 L187.747312,112.745754 L183.94114,112.745754 L183.94114,100.010173 C183.94114,95.451637 181.590787,92.8664193 177.361516,92.8664193 C173.131677,92.8664193 170.029529,96.2034315 170.029529,100.433271 L170.029529,112.745754 L166.176156,112.745754 L166.176156,79.8487733 L169.982897,79.8487733 L169.982897,79.8959737'
                id='Fill-11'
              ></path>
              <polyline
                id='Fill-12'
                points='202.8014 112.863471 202.8014 112.81627 202.754768 112.81627 202.8014 112.863471'
              ></polyline>
              <path
                d='M207.363917,87.9791863 C207.363917,84.5011416 208.961907,82.9503522 211.453292,82.9503522 C211.502768,82.9503522 211.549399,82.9554703 211.598306,82.9566076 L212.466111,79.6446173 C212.10045,79.5974169 211.732514,79.5667082 211.358892,79.5667082 C206.471659,79.5667082 203.557744,82.6688557 203.557744,87.9791863 L203.557744,89.8114722 L198.998639,89.8114722 L198.998639,93.1962535 L203.557744,93.1962535 L203.557744,112.745754 L207.363917,112.745754 L207.363917,93.1962535 L211.97534,93.1962535 L211.97534,89.8114722 L207.363917,89.8114722 L207.363917,87.9791863'
                id='Fill-13'
              ></path>
              <path
                d='M227.005544,109.52134 C226.7013,109.569678 226.401037,109.596975 226.112717,109.596975 C223.339835,109.596975 222.447007,107.858521 222.447007,105.132839 L222.447007,93.1666821 L227.392814,93.1666821 L227.392814,89.8273952 L222.493639,89.8273952 L222.493639,82.7626879 L218.639697,83.1852169 L218.639697,89.8273952 L213.987898,89.8273952 L213.987898,93.1666821 L218.639697,93.1666821 L218.639697,105.132839 C218.639697,110.255506 220.99005,113.16942 225.971684,113.075588 C226.586427,113.056253 227.178991,112.984031 227.766437,112.862333 L227.005544,109.52134'
                id='Fill-14'
              ></path>
              <path
                d='M138.299479,5.31999815 L135.516361,22.0027844 L125.624747,10.3596372 L138.299479,5.31999815 Z M135.57721,29.1778156 L138.053241,38.321047 L119.118483,43.5364081 L135.57721,29.1778156 Z M94.35874,24.5823153 L104.51024,11.3195685 L122.139878,11.3195685 L133.408266,24.5823153 L94.35874,24.5823153 Z M132.102577,27.8601848 L113.747304,43.8719291 L95.0502544,27.8601848 L132.102577,27.8601848 Z M101.110673,10.3693048 L92.0390957,22.2205887 L89.2537027,5.38482763 L101.110673,10.3693048 Z M108.310158,43.5307213 L89.1951287,38.3255965 L91.8144672,29.4030127 L108.310158,43.5307213 Z M111.839384,47.8879448 L108.231111,51.8590348 C105.173321,48.9542192 101.811287,46.375257 98.2223495,44.1801535 L111.839384,47.8879448 Z M129.342206,44.1193048 C125.753269,46.3508037 122.399196,48.9633181 119.360172,51.8954303 L115.675697,47.8833954 L129.342206,44.1193048 Z M142.487805,0.127384242 L122.583448,8.04226764 L104.031412,8.04226764 L85.0517288,0.0631234414 L89.3628892,26.1188877 L85.2229014,40.2227118 L96.0244027,46.6806379 C101.139107,49.7384284 105.720391,53.5645051 109.640868,58.0513876 L113.846823,62.8658295 L118.260914,57.8028746 C122.048321,53.4592994 126.459,49.7298982 131.370686,46.7187395 L141.963482,40.2227118 L138.148779,26.1382229 L142.487805,0.127384242 L142.487805,0.127384242 Z'
                id='Fill-15'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  ),
  viewBox: '0 0 229 125'
})
