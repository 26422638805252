export const StatStyle = {
  parts: ['label', 'number'],
  // Styles for the base style
  baseStyle: {
    label: {
      color: 'gray.500',
      lineHeight: 1,
      mb: 2
    },
    number: {
      lineHeight: 1
    }
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {}
}
